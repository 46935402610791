<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1 class="highlighted-color1">
          <span>Campanhas</span>
        </h1>
      </div>
      <div class="d-flex">
        <CreateCampaignButton />
      </div>
    </div>
    <!-- CAMPAIGNS -->
    <div class="mt-4">
      <template v-if="user">
        <CampaignListTable />
      </template>
    </div> <!-- END CAMPAIGNS -->
  </div>
</template>

<script>

import { mapState } from 'vuex'

import CampaignListTable from '@/components/campaigns/CampaignListTable'
import CreateCampaignButton from '@/components/campaigns/CreateCampaignButton'

export default {
  components: {
    // Button,
    CampaignListTable,
    CreateCampaignButton
  },
  data () {
    return {}
  },
  metaInfo: {
    title: 'Campanhas'
  },
  created () {
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  }
}
</script>
